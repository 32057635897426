import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { GuardedComponent } from './components/guarded/guarded.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PageCanMatch } from './guards/page.canmatch';
import { ProfileCanMatch } from './guards/profile.canmatch';
import { IsContentLoaded } from './guards/content.canmatch';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canMatch: [IsContentLoaded],
    loadChildren: () => {
      if (environment.license === 'vibe') {
        return import(`./components/licenses/vibe/home/home.module`).then(
          (m) => m.HomeModule
        );
      } else if (environment.license === 'sonar') {
        return import(`./components/licenses/sonar/home/home.module`).then(
          (m) => m.HomeModule
        );
      } else {
        return import(`./components/home/home.module`).then(
          (m) => m.HomeModule
        );
      }
    },
  },
  {
    path: '404',
    canMatch: [IsContentLoaded],
    component: NotFoundComponent,
  },
  {
    path: 'offline',
    canMatch: [IsContentLoaded],
    component: NotFoundComponent,
  },
  {
    path: 'guarded',
    canMatch: [IsContentLoaded],
    component: GuardedComponent,
  },
  {
    path: 'finalize',
    canMatch: [IsContentLoaded],
    loadChildren: () =>
      import('./components/finalize/finalize.module').then(
        (m) => m.FinalizeModule
      ),
  },
  {
    path: 'calls',
    canMatch: [IsContentLoaded],
    loadChildren: () =>
      import('./components/calls/calls.module').then((m) => m.CallsModule),
  },
  {
    path: 'artists',
    canMatch: [IsContentLoaded],
    loadChildren: () =>
      import('./components/artists/artists.module').then(
        (m) => m.ArtistsModule
      ),
  },
  {
    path: 'organisations/new',
    canMatch: [IsContentLoaded],
    loadChildren: () =>
      import(
        './components/organisations/organisations-create/organisations-create.module'
      ).then((m) => m.OrganisationsCreateModule),
  },
  {
    path: 'auth',
    canMatch: [IsContentLoaded],
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'invites',
    canMatch: [IsContentLoaded],
    loadChildren: () =>
      import('./components/invites/invites.module').then(
        (m) => m.InvitesModule
      ),
  },
  {
    path: 'admin',
    canMatch: [IsContentLoaded],
    loadChildren: () =>
      import('./components/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'playlists',
    canMatch: [IsContentLoaded],
    loadChildren: () =>
      import('./components/playlists/playlists.module').then(
        (m) => m.PlaylistsModule
      ),
  },
  {
    path: 'statusboard',
    canMatch: [IsContentLoaded],
    loadChildren: () =>
      import('./components/statusboard/statusboard.module').then(
        (m) => m.StatusboardModule
      ),
  },
  {
    path: 'contact',
    canMatch: [() => environment.license === 'sonar'],
    loadComponent: () =>
      import(`./components/licenses/sonar/contact/contact.component`).then(
        (m) => m.ContactComponent
      ),
  },
  {
    path: 'pages/:slug',
    canMatch: [IsContentLoaded, PageCanMatch],
    loadChildren: () =>
      import('./components/page/page.module').then((m) => m.PageModule),
  },
  {
    path: ':slug',
    canMatch: [IsContentLoaded, ProfileCanMatch],
    loadChildren: () =>
      import('./components/artist/artist.module').then((m) => m.ArtistModule),
    data: { profileType: 'artist' },
  },
  {
    path: ':slug',
    canMatch: [IsContentLoaded, ProfileCanMatch],
    loadChildren: () =>
      import('./components/organisation/organisation.module').then(
        (m) => m.OrganisationModule
      ),
    data: { profileType: 'organisation' },
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      scrollPositionRestoration: 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
